.login-bg {
    background-image: url("../../images/login_bg.jpg");
    background-color: #06255b;
    background-size: cover;
    background-repeat: no-repeat;
}
@media(max-width:960px) {
    .login-bg{
        background-size: cover;
    }
}

@media(max-width:1024px) {
    .login-bg{
        background-size: cover;
    }
}

.login-strip-bg {
    background-color: #1d428a;
    background-size: cover;
}

.appTitle {
    font-family: "Action NBA  Web";
    font-size: 5.5rem;
    font-weight: 500;
    color: #ffffff;
}