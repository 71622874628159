@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  background: #f6f6f6;
}

.ActionNBA-Light-Web {
  font-family: "Action NBA  Web";
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

.ActionNBA-LightItalic-Web {
  font-family: "Action NBA  Web";
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

.ActionNBA-Medium-Web {
  font-family: "Action NBA  Web";
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.ActionNBA-MediumItalic-Web {
  font-family: "Action NBA  Web";
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

.ActionNBA-Bold-Web {
  font-family: "Action NBA  Web";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

.ActionNBA-BoldItalic-Web {
  font-family: "Action NBA  Web";
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Action NBA  Web";
  src: url("fonts/ActionNBA-Light-Web.eot");
  src: url("fonts/ActionNBA-Light-Web.eot?#iefix") format("embedded-opentype"),
    url("fonts/ActionNBA-Light-Web.woff2") format("woff2"),
    url("fonts/ActionNBA-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Action NBA  Web";
  src: url("fonts/ActionNBA-LightItalic-Web.eot");
  src: url("fonts/ActionNBA-LightItalic-Web.eot?#iefix") format("embedded-opentype"),
    url("fonts/ActionNBA-LightItalic-Web.woff2") format("woff2"),
    url("fonts/ActionNBA-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Action NBA  Web";
  src: url("fonts/ActionNBA-Medium-Web.eot");
  src: url("fonts/ActionNBA-Medium-Web.eot?#iefix") format("embedded-opentype"),
    url("fonts/ActionNBA-Medium-Web.woff2") format("woff2"),
    url("fonts/ActionNBA-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Action NBA  Web";
  src: url("fonts/ActionNBA-MediumItalic-Web.eot");
  src: url("fonts/ActionNBA-MediumItalic-Web.eot?#iefix") format("embedded-opentype"),
    url("fonts/ActionNBA-MediumItalic-Web.woff2") format("woff2"),
    url("fonts/ActionNBA-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Action NBA  Web";
  src: url("fonts/ActionNBA-Bold-Web.eot");
  src: url("fonts/ActionNBA-Bold-Web.eot?#iefix") format("embedded-opentype"),
    url("fonts/ActionNBA-Bold-Web.woff2") format("woff2"),
    url("fonts/ActionNBA-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Action NBA  Web";
  src: url("fonts/ActionNBA-BoldItalic-Web.eot");
  src: url("fonts/ActionNBA-BoldItalic-Web.eot?#iefix") format("embedded-opentype"),
    url("fonts/ActionNBA-BoldItalic-Web.woff2") format("woff2"),
    url("fonts/ActionNBA-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

.headShot {
  padding: 16px 0;
  background-image: url("images/headshot_bg.png");
  display: block;
  width: 100%;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
}

.profile-summary {
  background-color: #1d428a;
  width: 100%;
  color: #fff;
  padding-bottom: 16px;
}

.profile-summary>div {
  padding: 0 16px;
}

.MuiTabs-indicator {
  background-color: #fff !important;
}

.profile-summary h6 {
  font-size: 1.3rem;
  font-family: "Action NBA  Web" !important;
  font-weight: 500 !important;
}

.subtitle1 {
  font-size: 1.4rem !important;
  font-family: "Action NBA  Web" !important;
  font-weight: 500 !important;
}

.headerTitle {
  font-size: 2rem !important;
  font-family: "Action NBA  Web" !important;
  font-weight: 500 !important;
}

.MuiMenu-list {
  padding: 0 !important;
}

.MenuItem {
  background: #1d428a !important;
  color: #fff !important;
}

.MenuItem.selected,
.MenuItem.selected:focus,
.MenuItem.selected:hover,
.MenuItem.selected:active {
  /* Increase the specificity */
  background: #1d428a !important;
  color: #fff !important;
}

.alphaSort {
  padding: 8px 12px !important;
  color: #ffffff !important;
  background-color: #1d428a !important;
  margin: 0 8px !important;
  border-radius: 4px !important;
}

.popup-title {
  background: #1d428a !important;
}

#customized-dialog-title button {
  color: #ffffff !important;
}

.popup-title h6 p {
  color: #fff;
}

.popup-container {
  background-color: #ffffff !important;
  border-bottom: 1px solid #ccc;
}

.bookmarkButton {
  text-align: center;
}

.MuiDataGrid-sortIcon {
  color: #ffffff !important;
}

@media (max-width: 599.95px) {
  .MuiTabs-scrollButtonsDesktop {
    display: inline-flex !important;
  }

  .yearExperience {
    padding: 20px 10px 10px 10px;
  }

  .bookmarkButton {
    text-align: left;
  }
}

@media (max-width: 767px) {
  #my-unique-id {
    width: 280px !important;
    height: 176px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 823px) {
  #my-unique-id {
    width: 400px !important;
    height: 180px !important;
  }
}

.MuiCardHeader-action div[x-placement="bottom-end"] ul,
.MuiTableCell-body div[x-placement="left-start"] ul {
  padding: 0 !important;
}

.MuiExpansionPanel-root.Mui-expanded {
  margin: 1px 0 !important;
}

.commissioner-image {
  display: flex;
  flex-direction: row;
  background: #f4f4f4;
  padding: 0 1rem;
  border-radius: 8px;
}

.commissioner-image>p img {
  border-radius: 6px;
  margin-right: 16px;
}

.topHeading {
  font-family: "Action NBA  Web" !important;
  font-weight: 500 !important;
  padding-bottom: 8px;
}

.primaryText {
  color: #3869c5;
  font-weight: 600;
}

.bullet-bg {
  background: #cdd5e8;
  font-weight: 600;
  padding: 16px;
  line-height: 1.2;
}

.bullet-bg li {
  padding-bottom: 16px;
}

.bullet-bg li:last-child {
  padding-bottom: 0;
}

.commissioner-name {
  font-family: "Action NBA  Web" !important;
  font-weight: 500 !important;
  font-size: 2.2rem;
  margin: 0;
  line-height: 1;
}

.league {
  color: #1d428a;
  font-weight: 500;
}

.headLogo {
  margin: 15px 0 0 -25px;
}

.title-league {
  margin: unset;
}

.divider {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
}

/***** Questionnaire CSS *****/

.react-quiz-container {
  width: 640px !important;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 3px 2px #ccc;
}

.react-quiz-container h2 {
  color: #1d428a;
  font-size: 2rem;
  margin: 01rem 0 2rem 0;
}

.react-quiz-container h2+div {
  font-size: 1.2rem;
  font-family: "Roboto";
  font-style: italic;
}

.react-quiz-container .quiz-synopsis {
  margin: 15px 0px;
  font-size: 1.2rem;
  font-style: italic;
}

.react-quiz-container .startQuizWrapper .startQuizBtn {
  color: #fff !important;
  background: #1d428a !important;
  border-radius: 8px !important;
  font-weight: 600;
  font-family: "Roboto";
  font-size: 1.2rem !important;
}

.questionWrapperBody .questionModal+div {
  font-size: 1.2rem;
  font-style: italic;
}

.questionWrapperBody h3 {
  font-size: 1.2rem;
}

.react-quiz-container .selection-tag.single {
  background: #3f51b5;
  font-size: 1.2rem;
}

.react-quiz-container .number-of-selection {
  background: #673ab7;
  margin-left: 5px;
  font-size: 1.2rem;
}

.react-quiz-container .questionWrapper .btn {
  font-size: 1.2rem !important;
  font-weight: normal;
}

.react-quiz-container .nextQuestionBtn.btn {
  background: #3f51b5;
  color: #fff;
  float: right;
}

.react-quiz-container .checkAnswerBtn.btn {
  background: #3f51b5;
  color: #fff;
  float: left;
}

.react-quiz-container .checkAnswerBtn.btn:disabled {
  cursor: not-allowed !important;
  background-color: gray !important;
}

.react-quiz-container .userInput.btn {
  color: #fff;
}

.startQuizWrapper {
  display: flex;
  justify-content: center;
}

.sidebar-backdrop.show {
  opacity: 0 !important;
  visibility: hidden !important;
}

#sidebar-parent {
  top: 64px !important;
}

.sidebar-main {
  width: 200px !important;
}

@media only screen and (max-width: 600px) {
  .sidebar-main {
    display: none;
  }
}

/* .incorrect:after {
  content: "<p>&#x2717;</p>";
}
.correct:after {
  content: "<p>&#x2713;</p>";
} */
/***** Questionnaire CSS *****/

.leaflet-container {
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #bdbdbd;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
}

.cke_contents {
  height: 250px !important;
}
.rdw-editor-main{
  height: 350px !important;
}

@media screen and (max-width: 1320px) {
  .headerTitle {
    font-size: 1.5rem !important;
  }
}

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

input[type=file] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}

input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #0d45a5;
}
