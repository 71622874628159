.menus {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0;
}

.menu-items {
  position: relative;
  font-size: 14px;
  min-width: fit-content;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 15.6px 1rem;
  text-transform: uppercase;
  max-height: 48px;
  display: flex;
  align-items: center;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #112751;
  /* color: #1d428a; */
}

.dropdown .menu-items a:hover,
.dropdown .menu-items button:hover {
  background-color: #bed4ff;
  font-weight: bold;
  /* color: #1d428a; */
}

/* .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  } */

/* .arrow {
    position: relative;
    text-align: center;
    padding: 12px;
    margin-bottom: 6px;
    height: 30px;
    width: 20px;
  } */

.arrow {
  position: relative;
  text-align: center;
  margin-bottom: 3px;
  width: 11px;
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
}

.arrow:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5px;
  width: 51%;
  background: #fff;
  transform: skew(0deg, 45deg);
}

.arrow:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 1.5px;
  width: 50%;
  background: #fff;
  transform: skew(0deg, -45deg);
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
  color: #112751;
  font-weight: 400;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown .menu-items button {
  display: flex;
  justify-content: space-between;
}

.dropdown .menu-items a,
.dropdown .menu-items button {
  text-transform: capitalize;
  padding: 0.4rem 1rem;
  height: auto;
}

@media screen and (max-width: 960px) {
  .menu-items {
    max-height: unset;
  }

  .dropdown.show {
    border-radius: 0;
  }

  .menu-items button {
    justify-content: space-between;
  }

  .menu-items>ul.dropdown.show {
    position: relative;
  }
}