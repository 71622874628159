.appbar-nba-logo {
  height: 35px;
  width: 'auto';
  background-size: 50px;
  cursor:pointer;
  background-repeat: no-repeat;
  background-position: center;
}

.appbar-nba-logo-rightMargin {
  margin-right: 16px;
}
