.error-ui-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    align-self: center;
    background-color: #f5f8ff;
    width: 100%;
    height: 100vh;
}

.error-ui-icon {
    color: #8c9dc4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10rem !important;
    margin-bottom: 25px;
}

.error-ui-subtext {
    margin-top: 5px;
    width: 250px;
    text-align: center;
}

.error-ui-fab-home {
    margin: 8px !important;
}

.error-ui-fab-reload {
    margin: 8px !important;
}

